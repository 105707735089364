import React, {
  useCallback,
  useState,
} from 'react';

import {
  DateInput,
  required,
  useRecordContext,
} from 'react-admin';

import Typography from '@mui/material/Typography';

import { useConstantContext } from './ConstantsContext';
import useCustomRpc from '../../hooks/useCustomRpc';
import ModalButton from './ModalButton';

const validateDate = [required()];

const FreezeAccountButton = () => {
  const record = useRecordContext();
  const { constants } = useConstantContext();
  const { accountStatuses } = constants;

  const [date, setDate] = useState('');

  const { id: accountId, status } = record || {};

  const { mutate: freezeAccount } = useCustomRpc({
    httpMethod: 'PUT',
    path: `account/${accountId}/freeze`,
    successMessage: 'Account frozen',
    errorMessage: 'Failed freezing account',
    shouldRefresh: true,
  });

  const onClick = useCallback(() => {
    freezeAccount({ date });
    return true;
  }, [date, freezeAccount]);

  const onChange = useCallback((e) => {
    setDate(e.target.value);
  }, [setDate]);

  if (!accountId
    || status !== accountStatuses.ACTIVE
    || status === accountStatuses.FROZEN
  ) return null;

  return (
    <ModalButton
      title="Mark Account as Deceased"
      onClick={onClick}
      right
    >
      <Typography variant="h6" color="error" align="center">WARNING</Typography>
      <Typography variant="body2" align="center">Marking the account as Deceased will pause all ledger functions on it.</Typography>
      <DateInput validate={validateDate} name="date" label="Date of passing" onChange={onChange} />
    </ModalButton>
  );
};

export default FreezeAccountButton;
