import { Typography, TextField } from '@mui/material';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useRecordContext,
} from 'react-admin';

import SelectArrayInput from '../input/SelectArrayInput';

import { isBeforeFactory } from '../../utils/signup/compareApplicationSteps';
import ModalButton from '../utils/ModalButton';
import numberFormat from '../utils/numberFormat';
import { useConstantContext } from '../utils/ConstantsContext';
import useCustomRpc from '../../hooks/useCustomRpc';

const getTotalRefinancing = (refinancedAccounts, refinancableAccounts) => (refinancedAccounts || [])
  .reduce((sum, refinancedAccountId) => {
    if (!refinancableAccounts || refinancableAccounts.length === 0) return sum;

    const refinancedAccountData = refinancableAccounts
      .find(({ account }) => account === refinancedAccountId);
    if (!refinancedAccountData) throw new Error('Could not find id of refinanced account');

    return sum + refinancedAccountData.amount;
  }, 0);

const AddRefinancingButton = () => {
  const record = useRecordContext();
  const { constants } = useConstantContext();
  const { applicationSteps, orderedApplicationSteps } = constants;

  const {
    id,
    refinancableAccounts,
    refinancedAccounts,
    applicationStep,
    rejectedByCustomer,
  } = record || {};

  const { mutate: updateRefinancing } = useCustomRpc({
    httpMethod: 'PUT',
    path: `application/${id}/refinancing`,
    successMessage: 'Application amount updated successfuly',
    errorMessage: 'Could not update application amount',
    shouldRefresh: true,
  });

  const refinancableAccountsFormatted = useMemo(() => (refinancableAccounts || [])
    .map((refinancableAccount) => ({
      id: refinancableAccount.account,
      name: `${refinancableAccount.publicId} - ${numberFormat(refinancableAccount.amount, 2, { unit: 'kr' })}`,
    })), [refinancableAccounts]);

  const [newRefinancing, setNewRefinancing] = useState(refinancedAccounts || []);
  const [totalRefinancing, setTotalRefinancing] = useState(0);
  const [newAdditionalPayout, setNewAdditionalPayout] = useState(0);

  const [totalAmount, setTotalAmount] = useState(record.amount);
  const [errorTotalAmount, setErrorTotalAmount] = useState('');

  useEffect(() => {
    setTotalAmount(record.amount);
  }, [record.amount]);

  useEffect(() => {
    const updatedTotalRefinancing = getTotalRefinancing(newRefinancing, refinancableAccounts);
    setTotalRefinancing(updatedTotalRefinancing);

    if ((totalAmount - updatedTotalRefinancing) < 0) {
      setNewAdditionalPayout(0);
      setErrorTotalAmount(`Should be greater or equal to the total refinancing amount: ${totalRefinancing}`);
    } else {
      setNewAdditionalPayout(totalAmount - updatedTotalRefinancing);
    }
  }, [refinancableAccounts, totalAmount, newRefinancing, totalRefinancing]);

  const onClick = useCallback(() => {
    updateRefinancing({
      refinancedAccounts: newRefinancing,
      newAmount: totalAmount,
    });
  }, [newRefinancing, totalAmount, updateRefinancing]);

  const onChange = useCallback((e) => {
    setNewRefinancing(e.target.value);
  }, []);

  const onAmountChange = useCallback((e) => {
    setTotalAmount(e.target.value);
    setErrorTotalAmount('');
  }, []);

  const notAllowed = !isBeforeFactory(orderedApplicationSteps)(
    applicationStep,
    applicationSteps.DECISION_TAKEN,
  ) || rejectedByCustomer;
  if (notAllowed) return null;
  return (
    <ModalButton
      title="Update refinancing"
      onClick={onClick}
    >
      <SelectArrayInput
        fullWidth
        choices={refinancableAccountsFormatted}
        source="refinancedAccounts"
        onChange={onChange}
        helperText={`Total refinancing ${numberFormat(totalRefinancing, 2, { unit: 'kr' })}`}
        style={{ marginBottom: '1.5rem' }}
      />
      <TextField
        label="Total application amount"
        value={totalAmount}
        onChange={onAmountChange}
        error={!!errorTotalAmount}
        helperText={errorTotalAmount || 'New application amount will be the value above.'}
        fullWidth
      />
      <Typography style={{ marginTop: '2rem' }}>Amount paid to customer {numberFormat(newAdditionalPayout, 2, { unit: 'kr' })}</Typography>
      <Typography color="error" variant="caption" style={{ marginBottom: '1rem' }}>This amount might get lower if the total application amount is higher than the decision engine boundaries</Typography>
      <Typography style={{ marginBottom: '1rem' }}>The &quot;Loan Terms&quot; will be updated after submitting the details</Typography>
    </ModalButton>
  );
};

export default AddRefinancingButton;
